import { toAbsoluteUrl } from "helper";

const CogSpinner = () => {
    return (
        <div className="upload-container-loader-spinner">
            <span role="img" aria-label="setting" 
                className="anticon anticon-setting anticon-spin" 
                style={ { fontSize: "36px", 
                    color: "#854075", animationDuration:" 1.5s" } }>
                <img alt="" src={ toAbsoluteUrl("/assets/cog.svg") }
                />
            </span>
        </div>
    );
};
export default CogSpinner;
