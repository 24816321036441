import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useLocation } from "react-router-dom";
import { useLayout } from "../../context";

const Sidebar = () => {
    const container = useRef(null);
    const [viewingParentMenu, setViewingParentMenu] = useState();
    const [selectedParentMenu, setSelectedParentMenu] = useState();
    const { setContainerClassnames, addContainerClassname, menuHiddenBreakpoint, 
        subHiddenBreakpoint, containerClassnames, menuClickCount } = useLayout();

    const handleDocumentClick = (e) => {
        let isMenuClick = false;
        if (
            e.target &&
        e.target.classList &&
        (e.target.classList.contains("menu-button") ||
            e.target.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
        e.target.parentElement.classList &&
        (e.target.parentElement.classList.contains("menu-button") ||
            e.target.parentElement.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
        e.target.parentElement.parentElement &&
        e.target.parentElement.parentElement.classList &&
        (e.target.parentElement.parentElement.classList.contains("menu-button") ||
            e.target.parentElement.parentElement.classList.contains(
                "menu-button-mobile"
            ))
        ) {
            isMenuClick = true;
        }
        if (
            container.current.contains(e.target) || container.current === e.target ||
        isMenuClick
        ) {
            return;
        }
        toggle(e);
    //setViewingParentMenu("");
    };

    const getMenuClassesForResize = (classes) => {
        let nextClasses = classes.split(" ").filter(x => x !== "");
        const windowWidth = window.innerWidth;
        if (windowWidth < menuHiddenBreakpoint) {
            nextClasses.push("menu-mobile");
        } else if (windowWidth < subHiddenBreakpoint) {
            nextClasses = nextClasses.filter(x => x !== "menu-mobile");
            if (
                nextClasses.includes("menu-default") &&
          !nextClasses.includes("menu-sub-hidden")
            ) {
                nextClasses.push("menu-sub-hidden");
            }
        } else {
            nextClasses = nextClasses.filter(x => x !== "menu-mobile");
            if (
                nextClasses.includes("menu-default") &&
          nextClasses.includes("menu-sub-hidden")
            ) {
                nextClasses = nextClasses.filter(x => x !== "menu-sub-hidden");
            }
        }
        return nextClasses;
    };


    const toggle = () => {
        const currentClasses = containerClassnames
            ? containerClassnames.split(" ").filter(x => x !== "")
            : "";

        if (currentClasses.includes("menu-sub-hidden") && menuClickCount === 3) {
            setContainerClassnames(2, containerClassnames);
        } else if (
            currentClasses.includes("menu-hidden") ||
        currentClasses.includes("menu-mobile")
        ) {
            setContainerClassnames(0, containerClassnames);
        }
    };

    const handleProps = () => {
        addEvents();
    };

    const addEvents = () => {
        ["click", "touchstart"].forEach(event =>
            document.addEventListener(event, handleDocumentClick, true)
        );
    };
    const removeEvents = () => {
        ["click", "touchstart"].forEach(event =>
            document.removeEventListener(event, handleDocumentClick, true)
        );
    };
    const setSelectedLiActive = () => {
        const oldli = document.querySelector(".sub-menu  li.active");
        if (oldli != null) {
            oldli.classList.remove("active");
        }

        /* set selected parent menu */
        const selectedlink = document.querySelector(".sub-menu  a.active");
        if (selectedlink != null) {
            selectedlink.parentElement.classList.add("active");
            setSelectedParentMenu(selectedlink.parentElement.parentElement.getAttribute(
                "data-parent"
            ));
        }else{
            let selectedParentNoSubItem = document.querySelector(".main-menu  li a.active");
            if(selectedParentNoSubItem!=null){
                setSelectedParentMenu(selectedParentNoSubItem.getAttribute(
                    "data-flag"
                ));
            }else if (selectedParentMenu === "") {
                setSelectedParentMenu("analysis");
            }

        }
    };
    useEffect(()=>{
        handleProps();
        setSelectedLiActive();
        return () => {
            removeEvents();
        };
    },[]);

    let location = useLocation();
    useEffect(() => {
        setSelectedLiActive();
    }, [location]);

    const openSubMenu = (e, selectedParent) => {
        e.preventDefault();
        const currentClasses = containerClassnames
            ? containerClassnames.split(" ").filter(x => x !== "")
            : "";

        if (!currentClasses.includes("menu-mobile")) {
            if (
                currentClasses.includes("menu-sub-hidden") &&
          (menuClickCount === 2 || menuClickCount === 0)
            ) {
                setContainerClassnames(3, containerClassnames);
            } else if (
                currentClasses.includes("menu-hidden") &&
          (menuClickCount === 1 || menuClickCount === 3)
            ) {
                setContainerClassnames(2, containerClassnames);
            } else if (
                currentClasses.includes("menu-default") &&
          !currentClasses.includes("menu-sub-hidden") &&
          (menuClickCount === 1 || menuClickCount === 3)
            ) {
                setContainerClassnames(0, containerClassnames);
            }
        } else {
            addContainerClassname(
                "sub-show-temporary",
                containerClassnames
            );
        }
        setViewingParentMenu(selectedParent);
    };
    
    return (
        <div className="sidebar" ref={ container }>
            <div className="main-menu">
                <div className="scroll">
                    <PerfectScrollbar
                        option={ { suppressScrollX: true, wheelPropagation: false } }
                    >
                        <ul className="list-unstyled nav flex-column">
                            <li className={ classnames({
                                active: selectedParentMenu === "dashboard" && 
                                viewingParentMenu===undefined || viewingParentMenu==="dashboard"
                            }) }
                            >
                                <NavLink to="/dashboard" onClick={ e => openSubMenu(e, "dashboard") }>
                                    <i className="iconsminds-monitor-analytics" />{ " " }
                                    Dashboard
                                </NavLink>
                            </li>
                            <li
                                className={ classnames({
                                    active: selectedParentMenu === "content" && 
                                    viewingParentMenu===undefined || viewingParentMenu==="content"
                                }) }
                            >
                                <NavLink
                                    to="content"
                                    onClick={ e => openSubMenu(e, "content") }
                                >
                                    <i className="iconsminds-globe-2" />{ " " }
                                    Content
                                </NavLink>
                            </li>
                            <li
                                className={ classnames({
                                    active: selectedParentMenu === "user" && 
                                    viewingParentMenu===undefined || viewingParentMenu==="user"
                                }) }
                            >
                                <NavLink
                                    to="/user"
                                    onClick={ e => openSubMenu(e, "user") }
                                >
                                    <i className="iconsminds-male-female" />{ " " }
                                    Users
                                </NavLink>
                            </li>
                            <li
                                className={ classnames({
                                    active: selectedParentMenu === "transactions" && 
                                    viewingParentMenu===undefined || viewingParentMenu==="transactions"
                                }) }
                            >
                                <NavLink
                                    to="/transactions"
                                    onClick={ e => openSubMenu(e, "transactions") }
                                >
                                    <i className="iconsminds-repeat-2" />{ " " }
                                    Transactions
                                </NavLink>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </div>

            <div className="sub-menu">
                <div className="scroll">
                    <PerfectScrollbar
                        option={ { suppressScrollX: true, wheelPropagation: false } }
                    >
                        <ul
                            className={ classnames({
                                "d-block nav": selectedParentMenu === "analysis" && 
                                viewingParentMenu==="" || viewingParentMenu==="analysis"
                            }) }
                            data-parent="analysis"
                        >
                            <li className="nav-item">
                                <NavLink to="/content/dashboard">
                                    <i className="simple-icon-map" />{ " " }
                                    Dashboard
                                </NavLink>
                            </li>
                        </ul>
                        <ul
                            className={ classnames({
                                "d-block nav": selectedParentMenu === "content" && 
                                viewingParentMenu==="" || viewingParentMenu==="content"
                            }) }
                            data-parent="content"
                        >
                            <li className="nav-item">
                                <NavLink to="/content/content/collection-list">
                                    <i className="simple-icon-list" />{ " " }
                                    Collection Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/content/nft-list">
                                    <i className="simple-icon-menu" />{ " " }
                                    Nft Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/content/consumable-list">
                                    <i className="simple-icon-layers" />
                                    Consumable Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/content/game-list">
                                    <i className="simple-icon-layers" />
                                    Game Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/content/game-item-list">
                                    <i className="simple-icon-layers" />
                                    Game Item Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/content/game-item-type-list">
                                    <i className="simple-icon-layers" />
                                    Game Item Type Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/content/in-app-products-list">
                                    <i className="iconsminds-shopping-bag" />
                                    In-App Products Management
                                </NavLink>
                            </li>
                        </ul>
                        <ul
                            className={ classnames({
                                "d-block nav": selectedParentMenu === "user" && 
                                viewingParentMenu==="" || viewingParentMenu==="user"
                            }) }
                            data-parent="user"
                        >
                            <li className="nav-item">
                                <NavLink to="/content/user/user-list">
                                    <i className="simple-icon-user" />
                                    User Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/user/role-list">
                                    <i className="simple-icon-key" />
                                    Role Management
                                </NavLink>
                            </li>
                        </ul>
                        <ul
                            className={ classnames({
                                "d-block nav": selectedParentMenu === "transactions" && 
                                viewingParentMenu==="" || viewingParentMenu==="transactions"
                            }) }
                            data-parent="transactions"
                        >
                            <li className="nav-item">
                                <NavLink to="/content/transactions/order-list">
                                    <i className="iconsminds-receipt-4" />
                                    Order Management
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/content/transactions/transaction-history-list">
                                    <i className="iconsminds-receipt-4" />
                                    Transaction History Management
                                </NavLink>
                            </li>

                        </ul>
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    );
};
export default Sidebar;
