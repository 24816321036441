import { Image } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [

    {
        dataIndex: "images",
        title: "Images",
        render: images => images.map(image => {

            return (
                <Image width={ 60 }
                    height={ 60 }
                    style={ { objectFit: "cover" } }
                    key={ "path" } src={ image["path"] } />
            );
        })
    },
    {
        dataIndex: "name",
        title: "Name"
    },
    {
        dataIndex: "description",
        title: "Description"
    },
    {
        dataIndex: "slug",
        title: "Slug"
    },
    {
        dataIndex: "game",
        title: "Game",
        render: (game) => (
            <Link to={ `/content/content/game/${game?.id}` }>{ game?.name }</Link>
        )

    },
    {
        dataIndex: "value",
        title: "Value"
    },
    {
        dataIndex: "currency_type",
        title: "Currency Type"
    },
    {
        dataIndex: "amount",
        title: "Amount"
    },
    {
        dataIndex: "type",
        title: "Type",
        render: (type) => type?.name
    }
];
const Path = "content/content/game-item-list";
const Url = "/game_item/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide } /> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide } /> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

