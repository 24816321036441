
import { useLayout } from "context";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./SideBar";
import TopNav from "./TopNav";

const Layout = ({ children }) => {

    const { containerClassnames, setContainerClassnames } = useLayout();
    const location = useLocation();

    useEffect(() => {
        setContainerClassnames(1, "menu-default sub-hidden");
    }, [location.pathname]);

    return (
        <div id="app-container" className={ containerClassnames }>
            <TopNav />
            <SideBar/>
            <main>
                <div className="container-fluid">
                    { children }
                </div>
            </main>
        </div>
    );
};

export default Layout;
