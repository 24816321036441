import { getStorage, removeStorage } from "./LocalStorageHelpers";

const ROOT_STATE_STORAGE_KEY = "root";
const requestHandler = async config => {
    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
    if(storeData){
        let access_token = null;
        const { tokens } = JSON.parse(storeData);
        if (tokens && tokens.access_token) {
            access_token = tokens.access_token;
            config.headers.Authorization = `Bearer ${access_token}`;
        }
    // if (config.url.indexOf('refreshToken') === -1 && access_token) {
    //   config.headers.Authorization = `Bearer ${access_token}`;
    // }
    }
    return config;
};
const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = async error => {
    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
    if(storeData){
        await removeStorage(ROOT_STATE_STORAGE_KEY);
        window.location.reload();
    }
    return Promise.reject(error);
};

const errorHandler = async error => {
    return Promise.reject(error);
};
export {
    requestHandler,
    errorHandler,
    responseSuccessHandler,
    responseErrorHandler
};
