import { Layout } from "components";
import { Route, Routes } from "react-router-dom";
import { CollectionDetailPage, CollectionPage, ConsumablePage, GameDetailPage, GameItemDetailPage, GameItemPage, GameItemTypePage, GamePage, InAppProductsPage, NftDetailPage, NftPage, OrderPage, RolePage, TransactionHistoryPage, UserDetailPage, UserListPage } from "views";

const PrivateRoutes = () => (
    <Layout>
        <Routes>
            <Route path="user/user-list/*" element={ <UserListPage /> } />
            <Route path="user/user/:id/*" element={ <UserDetailPage /> } />
            <Route path="user/role-list/*" element={ <RolePage /> } />
            <Route path="content/in-app-products-list/*" element={ <InAppProductsPage /> } />
            <Route path="content/game-list/*" element={ <GamePage /> } />
            <Route path="content/game/:id/*" element={ <GameDetailPage /> } />
            <Route path="content/game-item-list/*" element={ <GameItemPage /> } />
            <Route path="content/game-item/:id/*" element={ <GameItemDetailPage /> } />

            <Route path="content/game-item-type-list/*" element={ <GameItemTypePage /> } />
            <Route path="transactions/order-list/*" element={ <OrderPage /> } />
            <Route path="content/nft-list/*" element={ <NftPage /> } />
            <Route path="content/nft/:id/*" element={ <NftDetailPage /> } />
            <Route path="content/collection-list/*" element={ <CollectionPage /> } />
            <Route path="content/collection/:id/*" element={ <CollectionDetailPage /> } />
            <Route path="transactions/transaction-history-list/*" element={ <TransactionHistoryPage /> } />
            <Route path="content/consumable-list/*" element={ <ConsumablePage /> } />
        </Routes>
    </Layout>
);

export default PrivateRoutes;
