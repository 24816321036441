import { Image } from "antd";
import { booleanIcon } from "helper";
import { Link, Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        title: "Image",
        dataIndex: "image",
        render: image => (
            <Image width={ 60 }
                height={ 60 }
                style={ { objectFit: "cover" } }
                src={ image } />
        )

    },
    {
        title: "Name",
        dataIndex: "name"
    },
    {
        title: "Slug",
        dataIndex: "slug"
    },
    {
        title: "Amount",
        dataIndex: "amount"
    },
    {
        title: "Rank",
        dataIndex: "rank"
    },
    {
        title: "Type",
        dataIndex: "type"

    },
    {
        title: "Is listed",
        dataIndex: "is_listed",
        render: is_listed => booleanIcon(is_listed)
    },
    {
        title: "Collection Id",
        dataIndex: "collection_id"

    },
    {
        title: "Attributes",
        dataIndex: "attributes",
        render: attributes => (
            <ul>
                { attributes.map((attribute, index) => (
                    <li key={ index }>
                        { attribute.trait_type } : { attribute.value }
                    </li>
                )) }
            </ul>
        )

    },
    {
        title: "Owner",
        dataIndex: "owner",
        render: (owner) => (
            <Link to={ `/content/user/user/${owner?.id}` }>{ owner?.username }</Link>
        )

    },
    {
        title: "Collection",
        dataIndex: "collection",
        render: (collection) => (
            <Link to={ `/content/content/collection/${collection?.id}` }>{ collection?.name }</Link>
        )

    }
];
const Path = "content/content/nft-list";
const Url = "/nft/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide } /> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide } /> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

