import { Image, Tag } from "antd";
import { booleanIcon } from "helper";
import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";
import { Detail } from "./detail";

const Columns = [
    {
        dataIndex:"image",
        title: "Image",
        render: image => (
            <Image width={ 60 }
                height={ 60 }
                style={ { objectFit: "cover" } }
                src={ image } />
        )
    },
    {
        dataIndex:"is_new",
        title: "Is New",
        render: is_new => booleanIcon(is_new)
    },
    {
        dataIndex:"is_gift_get",
        title: "Is Gift Get",
        render: is_gift_get => booleanIcon(is_gift_get)
    },
    {
        dataIndex:"mail_is_allowed",
        title: "Mail Is Allowed",
        render: mail_is_allowed => booleanIcon(mail_is_allowed)
    },
    {
        dataIndex:"gift_date",
        title: "Gift Date"
    },
    {
        dataIndex:"email",
        title: "Email"
    },
    {
        dataIndex:"username",
        title: "Username"
    },
    {
        dataIndex:"full_name",
        title: "Full Name"
    },
    {
        dataIndex:"gsm",
        title: "Gsm"
    },
    {
        dataIndex:"unique_id",
        title: "Unique Id"
    },
    {
        dataIndex:"notification_id",
        title: "Notification Id"
    },
    {
        dataIndex:"coin",
        title: "Coin"
    },
    {
        dataIndex:"first_name",
        title: "First Name"
    },
    {
        dataIndex:"last_name",
        title: "Last Name"
    },
    {
        dataIndex:"source",
        title: "Source"
    },
    {
        dataIndex:"games",
        title: "Games",
        render: attributes => (
            <ul>
                { attributes.map((game, index) => (
                    <li key={ index }>
                        { game.game_name }
                    </li>
                )) }
            </ul>
        )
    },
    {
        title: "Roles",
        dataIndex: "roles",
        render: roles => roles.map(role => {
            const color = role.name.length > 5 ? "geekblue" : "green";
            return (
                <Tag color={ color } key={ role.id }>
                    { role.name.toUpperCase() }
                </Tag>
            );
        }) 
    }
];
const Path = "/content/user/user-list";
const Url = "/user/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide }/> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

