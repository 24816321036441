
import { Layout } from "antd";

const { Footer:AntdFooter } = Layout;

const Footer = (props) => (
    <AntdFooter { ...props }>Ratic Games ©2022</AntdFooter>
);

export default Footer;
