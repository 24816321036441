import { Button, Drawer, Form, Input, Select, notification } from "antd";
import { sourceOptions } from "constant/Table";
import { formatResult, get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const AddModal = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [roles, setRoles] = useState(undefined);

    const getRoles = async () => {
        const { result, success, message } = await post("/role/back-office/list",
            { filter: { active: 1 }, pageSize: 1000 });
        if (!success) {
            notification.error({
                description: message,
                message: "Warning",

                placement: "top"
            });
            return;
        }
        const roles_ = formatResult(result);
        setRoles(roles_);

    };

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    description: message,
                    message: "Warning",

                    placement: "top"
                });
                return;
            }
            setData({ ...result, roles: 
                formatResult(result?.roles) });
        }
    };

    useEffect(() => {
        setVisible(true);
        getRoles();
        getDataViaPrimaryKey();
    }, []);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSave = async (values) => {
        const _values  = { ...values, roles:values.roles?.map(item=>item["value"] ? item["value"] : item) };
        console.log(_values);
        const { success, message } = !primaryKey ? await post(`${Url}/add`, _values) :
            await put(`${Url}/${primaryKey}`, _values);
        if (!success) {
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={ 720 }
            title={ primaryKey ? "Edit User" : "Add User" }
            visible={ visible }
            onClose={ onClose }
        >

       
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={ [{ required: true, message: "Please type mail!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Gsm"
                    name="gsm"
                    rules={ [{ required: true, message: "Please type gsm!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Username"
                    name="username"
                    rules={ [{ required: true, message: "Please type username!" }] }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="First Name"
                    name="first_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="last_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={ [{ required: true, message: "Please type password!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Role" name="roles"
                    rules={ [{ required: true, message: "Please select role!" }] }
                    style={ { width: "100%" } }>
                    <Select mode="multiple" options={ roles } />
                </Form.Item>
                <Form.Item
                    label="Coin"
                    name="coin"
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Source" name="source"
                    required tooltip="Record Source" style={ { width: "100%" } }>
                    <Select options={ sourceOptions } />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddModal;
