import React, { createContext, useCallback, useContext, useState } from "react";

const LayoutContext = createContext();

export function useLayout() {
    return useContext(LayoutContext);
}

export function LayoutProvider({ children }) {
    const [containerClassnames, setClassNames] = useState("menu-default sub-hidden");
    const [loading, setLoading] = useState(false);
    const [subHiddenBreakpoint] = useState(1440);
    const [menuHiddenBreakpoint] = useState(768);
    const [menuClickCount, setMenuClickCount] = useState(0);

    const changeDefaultClassnames = useCallback((strCurrentClasses) => {
        setClassNames(strCurrentClasses);
    }, []);
  
    const addContainerClassname = useCallback((classname, strCurrentClasses) => {
        const newClasses = !strCurrentClasses.indexOf(classname) > -1 
            ? strCurrentClasses + " " + classname : strCurrentClasses;
        setClassNames(newClasses);
    },[]);

    const clickOnMobileMenu = useCallback((strCurrentClasses) => {
        const currentClasses = strCurrentClasses 
            ? strCurrentClasses.split(" ").filter(x => x !== "" && x !== "sub-show-temporary") : "";
        let nextClasses = "";
        if (currentClasses.includes("main-show-temporary")) {
            nextClasses = currentClasses.filter(x => x !== "main-show-temporary").join(" ");
        } else {
            nextClasses = currentClasses.join(" ") + " main-show-temporary";
        }
        setClassNames(nextClasses);
        setMenuClickCount(0);
    },[]);
    const setContainerClassnames = useCallback((clickIndex, strCurrentClasses) => {
        const currentClasses = strCurrentClasses ? strCurrentClasses.split(" ").filter(x => x !== "") : "";
        let nextClasses = "";
        if (clickIndex % 4 === 0) {
            if (currentClasses.includes("menu-default") && currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-default menu-sub-hidden";
            } else if (currentClasses.includes("menu-default")) {
                nextClasses = "menu-default";
            } else if (currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-sub-hidden";
            } else if (currentClasses.includes("menu-hidden")) {
                nextClasses = "menu-hidden";
            }
            clickIndex = 0;
        } else if (clickIndex % 4 === 1) {
            if (currentClasses.includes("menu-default") && currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-default menu-sub-hidden main-hidden sub-hidden";
            } else if (currentClasses.includes("menu-default")) {
                nextClasses = "menu-default sub-hidden";
            } else if (currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-sub-hidden main-hidden sub-hidden";
            } else if (currentClasses.includes("menu-hidden")) {
                nextClasses = "menu-hidden main-show-temporary";
            }
        } else if (clickIndex % 4 === 2) {
            if (currentClasses.includes("menu-default") && currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-default menu-sub-hidden sub-hidden";
            } else if (currentClasses.includes("menu-default")) {
                nextClasses = "menu-default main-hidden sub-hidden";
            } else if (currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-sub-hidden sub-hidden";
            } else if (currentClasses.includes("menu-hidden")) {
                nextClasses = "menu-hidden main-show-temporary sub-show-temporary";
            }
        } else if (clickIndex % 4 === 3) {
            if (currentClasses.includes("menu-default") && currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-default menu-sub-hidden sub-show-temporary";
            } else if (currentClasses.includes("menu-default")) {
                nextClasses = "menu-default sub-hidden";
            } else if (currentClasses.includes("menu-sub-hidden")) {
                nextClasses = "menu-sub-hidden sub-show-temporary";
            } else if (currentClasses.includes("menu-hidden")) {
                nextClasses = "menu-hidden main-show-temporary";
            }
        }
        if (currentClasses.includes("menu-mobile")) {
            nextClasses += " menu-mobile";
        }
        setClassNames(nextClasses);
        setMenuClickCount(clickIndex);
    }, []);
    const value = {
        subHiddenBreakpoint,
        changeDefaultClassnames,
        menuHiddenBreakpoint,
        containerClassnames,
        addContainerClassname,
        setContainerClassnames,
        clickOnMobileMenu,
        menuClickCount,
        loading,
        setLoading
    };

    return (
        <LayoutContext.Provider value={ value }>
            { children }
        </LayoutContext.Provider>
    );
}
