import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "assets/css/index.scss";
import "assets/css/App.less";
import "assets/plugins/flaticon/flaticon.css";
import "assets/plugins/flaticon2/flaticon.css";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(<BrowserRouter><App /></BrowserRouter>);
