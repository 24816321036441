import { Button, Form, Input, Row, Select } from "antd";
import { statusOptions } from "constant/Table";

const FilterMenu = ({ handleSubmit }) => {
    const filterOptions = [
        { value: "name", label: "Name" },
        { value: "slug", label: "Slug" }


    ];
    return (
        <Form
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ { active: statusOptions[1].value } }
            onFinish={ handleSubmit }
        >
            <div className="filterBox">
                <Row>
                    <div className="filterTitle">Filter Options</div>
                </Row>
                <Row>
      
                    <Form.Item label="Filter" name="filter" tooltip="Filter Input" style={ { width: "100%" } }>
                        <Input.Group compact>
                            <Form.Item name="filter_input" style={ { width: "50%" } } >
                                <Input />
                            </Form.Item>
                            <Form.Item name="filter_option" style={ { width: "50%" } } >
                                <Select options={ filterOptions } />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item label="Durum" name="active"
                        required tooltip="Veri durumu" style={ { width: "100%" } }>
                        <Select options={ statusOptions } />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Button style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">Filter</Button>
                </Row>
            </div>
        </Form>
    );
};
export default FilterMenu;
