import { Button, Form, Input, Modal, Select, notification } from "antd";
import { booleanOptions } from "constant/Table";
import { get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const AddModal = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [types, setTypes] = useState(undefined);

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    message: "Warning",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Modal visible={ visible } 
            title= { primaryKey ? "Edit Collection" : "Add Collection" }
            onCancel={ onClose }
            okButtonProps = { { style: { display: "none" } } }
            cancelButtonProps={ { style: { display: "none" } } }>
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={ [{ required: true, message: "Please type name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={ [{ required: true, message: "Please type title!" }] }
                >
                    <Input />
                </Form.Item>              
                <Form.Item
                    label="Sub Title"
                    name="sub_title"
                    rules={ [{ required: true, message: "Please type subtitle!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={ [{ required: true, message: "Please type description!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slug"
                    name="slug"
                    rules={ [{ required: true, message: "Please type slug!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Launched" name="launched"
                    required tooltip="Is Launched?" style={ { width: "100%" } }>
                    <Select options={ booleanOptions } />
                </Form.Item>
                <Form.Item
                    label="Collectibles"
                    name="collectibles"
                    rules={ [{ required: true, message: "Please type collectibles!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ungenerated"
                    name="un_generated"
                    rules={ [{ required: true, message: "Please type ungenerated!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Listed"
                    name="listed"
                    rules={ [{ required: true, message: "Please type listed!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Floor Price"
                    name="floor_price"
                    rules={ [{ required: true, message: "Please type floor price!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Logo"
                    name="logo"
                    rules={ [{ required: true, message: "Please type logo!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Background Image"
                    name="background_img"
                    rules={ [{ required: true, message: "Please type background image!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddModal;
