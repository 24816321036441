import { notification } from "antd";
import { formatResult, post } from "helper";
import { usePersistedState } from "helper/Hook";
import { removeStorage } from "helper/LocalStorageHelpers";
import { createContext, useContext, useEffect, useState } from "react";

const ROOT_STATE_STORAGE_KEY = "root";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [authState, setAuthState] = usePersistedState(ROOT_STATE_STORAGE_KEY, undefined);

    const value={
        authState,
        createSession: (data) => setAuthState(data),
        removeSession: async () => await removeStorage(ROOT_STATE_STORAGE_KEY)
    };
    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
}
