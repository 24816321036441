/* eslint-disable react-hooks/rules-of-hooks */
import { notification } from "antd";
import { useAuth } from "context";
import { get } from "helper";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CogSpinner from "./CogSpinner";

const Authenticated = ({ children, guard=false }) => {
    const [loading, setLoading] = useState(false);
    const { createSession, authState, removeSession } = useAuth();

    const handleUserData = async () => {
        if(!authState || !guard) return;
        setLoading(true);
        const { user, tokens, success, message } = await get("authenticate/back-office/me");
        setLoading(false);
        if(!success){
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            removeSession();
            window.location.reload();
            return;
        }
        createSession({ user, tokens });
    };

    useEffect(() => {
        handleUserData();
    }, []);

    if(loading) return <CogSpinner />;

    return children;
};

const Gate = ({ children, guard=false }) => {
    const { authState } = useAuth();
    
    if (authState && !guard) {
        return <Navigate to="/" />;
    }

    if (!authState && guard) {
        return <Navigate to="/auth/login" />;
    }
    return <Authenticated guard={ guard }>{ children }</Authenticated>;
};

export default Gate;
