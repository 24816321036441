import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        dataIndex:"name",
        title: "In-App Products Name"
    },
    {
        dataIndex: "sku",
        title: "Sku"
    },    
    {
        dataIndex: "store",
        title: "Store"
    },
    {
        dataIndex: "item_type",
        title: "Item Type"
    }
];
const Path = "content/content/in-app-products-list";
const Url = "/inapp_purchase/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide }/> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

