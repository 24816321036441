
import { Card, Col, Image, List, Row, Tag, Typography, notification } from "antd";
import { sourceOptions } from "constant/Table";

const { Title, Text } = Typography;
import { booleanIcon, formatResult, get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const Detail = ({ onHide }) => {

    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);



    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    description: message,
                    message: "Warning",

                    placement: "top"
                });
                return;
            }
            setData({ ...result });
        }
    };

    useEffect(() => {
        setVisible(true);
   
        getDataViaPrimaryKey();
    }, []);



    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSave = async (values) => {
        const _values  = { ...values, roles:values.roles?.map(item=>item["value"] ? item["value"] : item) };
        console.log(_values);
        const { success, message } =
            await put(`${Url}/${primaryKey}`, _values);
        if (!success) {
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };


    
    return (
        <Card style={ { width: "100vw", height: "100vh" } }> 
            <Row gutter={ 24 }>
                <Col span={ 8 }>
                    <Image
                        className="profile-picture"
                        src={ data?.logo }
                        alt="Collection Logo"
                        role="presentation"
                    />
                    <div style={ { marginBottom: 20 } }>
                        <Title level={ 2 }>{ data?.name }</Title>
                        




                    </div>
                </Col>
                <Col span={ 16 }>

                    <List
                        bordered
                        dataSource={ [
                            { title: "ID", value: data?.id },
                            { title: "Background Img", value: <Image width={ 120 } height={ 120 } src={ data?.background_img } alt="Game background" role="presentation" /> },
                            { title: "Description", value: data?.description },
                            { title: "Active", value: booleanIcon(data?.active) },
                            { title: "Slug", value: data?.slug },
                            { title: "Launched", value: booleanIcon(data?.launched) },
                            { title: "Collectibles", value: data?.collectibles },
                            { title: "Un Generated", value: data?.un_generated },
                            { title: "Listed", value: data?.listed },
                            { title: "Floor Price", value: data?.floor_price }
                         
                        ] }
                        renderItem={ (item) => (
                            <List.Item>
                                <Text strong>{ item.title }:</Text> { item.value ?? "N/A" }
                            </List.Item>
                        ) }
                    />

                </Col>

            </Row>

        </Card>



    );
};
export default Detail;
