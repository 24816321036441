import { Image } from "antd";
import { booleanIcon } from "helper";
import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        dataIndex: "logo",
        title: "Logo",
        render: logo => (<Image width={ 60 }
            height={ 60 }
            style={ { objectFit: "cover" } } src={ logo } />)
    },
    {
        dataIndex: "images",
        title: "Images",
        render: images => images.map(image => {

            return (
                <Image width={ 60 }
                    height={ 60 }
                    style={ { objectFit: "cover" } }
                    key={ "path" } src={ image["path"] } />
            );
        })
    },
    {
        dataIndex: "background_img",
        title: "Background Img",
        render: background_img => (<Image width={ 60 }
            height={ 60 }
            style={ { objectFit: "cover" } }
            src={ background_img } />)
    },
    {
        dataIndex:"name",
        title: "Name"
    },
    {
        dataIndex: "description",
        title: "Description"
    },
    {
        dataIndex: "slug",
        title: "Slug"
    },
    {
        dataIndex: "url",
        title: "Url"
    },
    {
        dataIndex: "studio",
        title: "Studio"
    },
    {
        dataIndex: "version",
        title: "Version"
    },
    {
        dataIndex: "play_store_url",
        title: "Play Store Url"
    },
    {
        dataIndex: "is_mini",
        title: "Is Mini",
        render: (is_mini) => booleanIcon(is_mini)
    },
    {
        dataIndex: "is_popular",
        title: "Is Popular",
        render: (is_popular) => booleanIcon(is_popular)
    }
];
const Path = "content/content/game-list";
const Url = "/game/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide } /> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide } /> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

