import { Avatar, Button, Col, Dropdown, Menu, Row, Typography } from "antd";
import { MenuIcon } from "components";
import { useAuth, useLayout } from "context";
import { Link, NavLink, useNavigate } from "react-router-dom";

const MenuItems = [
    { label: "Çıkış Yap", key: "logout" },
    { label: "My Profile", key: "profile" }
];

const TopNav = () => {
    const navigate = useNavigate();
    const { authState, removeSession } = useAuth();
    const { containerClassnames, menuClickCount, setContainerClassnames } = useLayout();

    const menuButtonClick = (e, menuClickCount, containerClassnames) => {
        e.preventDefault();
        setTimeout(() => {
            var event = document.createEvent("HTMLEvents");
            event.initEvent("resize", false, false);
            window.dispatchEvent(event);
        }, 350);
        setContainerClassnames(++menuClickCount, containerClassnames);
    };
   
    const handleLogout = async () => {
        await removeSession();
        window.location.reload();
    };
    
    const handleMenuClick = async (item) => {
        const { key } = item;
        if (key === "profile")
        {navigate("content/profile");}
        if(key === "logout"){
            handleLogout();
            return;
        }
        navigate(key);
    };
    if(!authState?.user) return;

    return (
        <nav className="navbar fixed-top">
            <Row gutter={ 24 } type="flex" align="middle" style={ { height: "100%", padding: "0 30px" } }>
                <Col span={ 6 }>
                    <NavLink
                        to="#"
                        className="menu-button d-none d-md-block"
                        onClick={ e => menuButtonClick(e, menuClickCount, containerClassnames) }
                    >
                        <MenuIcon />
                    </NavLink>
                </Col>
                <Col span={ 12 } align="middle">
                    <Link to="/content/analysis/map">
                        <Typography.Title align="center" level={ 4 } style={ { color: "#922c88" } }>
                            RATIC GAMES
                        </Typography.Title>
                    </Link>
                </Col>
                <Col span={ 6 } style={ { textAlign: "right" } }>
                    <Dropdown overlay={  <Menu items={ MenuItems } onClick={ handleMenuClick } /> }>
                        <Button type="text">
                            <span style={ { marginRight: 10 } }>{ authState?.user?.full_name }</span>
                            <Avatar className="profileAvatar" src={ authState?.user?.image } />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </nav>
    );
};


export default TopNav;
