import { Link, Route, Routes } from "react-router-dom";
import { AddModal } from "./add";



const Columns = [
    {
        title: "Transaction Key",
        dataIndex: "transaction_key"
    },
    {
        title: "Amount",
        dataIndex: "amount"
    },
    {
        title: "Current Amount",
        dataIndex: "current_amount"
    },
    {
        title: "Create Date",
        dataIndex: "create_date"
    },
    {
        title: "Gift Type",
        dataIndex: "gift_type"
    },
    {
        title: "Item",
        dataIndex: "item",
        render: (item, record) => {
            const { type } = record;
            if (type == "game_transaction_history"){
                return    <Link to={ `/content/content/game/${item?.id}` }>{ item?.name }</Link>;
            }
            if (type == "nft_transaction_history"){
                return   <Link to={ `/content/content/nft/${item?.id}` }>{ item?.name }</Link>;
            }

           
        }

    },
    {
        title: "Type",
        dataIndex: "type"
    },
    {
        title: "Transaction Type",
        dataIndex: "transaction_type"
    }
];

const Path = "/content/transactions/transaction-history-list";
const Url = "/transaction_history/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide } /> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide } /> } />

    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

