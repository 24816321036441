import { Table } from "antd";
import { handleTableChange } from "helper";
import { useEffect, useState } from "react";
import CogSpinner from "./CogSpinner";
import EmptyMessage from "./EmptyMessage";

const DataTable = ({ useData,columns, queryParams, setQueryParams }) => {
    const { pageSize } = queryParams;
    const [initialRender, setInitialRender] = useState(true);
    const { data, error, mutate } = useData(queryParams);

    useEffect(() => {
        if (data) {
            if(initialRender){
                setInitialRender(false);
                return;
            }
            mutate();
        }
        return () => setInitialRender(true);
    }, [queryParams]);

    if (!data && !error) {
        return <CogSpinner />;
    }
    
    const { result, total_count } = data;
    if(!result || result.length == 0){
        return <EmptyMessage />;
    }

    const pagination = {
        pageSize: pageSize,
        showLessItems:true,
        showTotal: (total, range) => 
            <span className="text-muted">
                From
                <span className="paginationText">
                    { total }</span> count 
                <span className="paginationText"> { range[0] }</span> - 
                <span className="paginationText">{ range[1] }</span> between
            </span>,
        total: total_count
    };

    return (
        <Table
            columns={ columns }
            showSorterTooltip={ { title: "Update order!" } }
            dataSource={ result }
            rowKey="id"
            onChange={ handleTableChange(setQueryParams) }
            pagination={ pagination }
        />
    );
};
export default DataTable;
