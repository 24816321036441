
import { Card, Col, Image, List, Row, Tag, Typography, notification } from "antd";
import { sourceOptions } from "constant/Table";

const { Title, Text } = Typography;
import { booleanIcon, formatResult, get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const Detail = ({ onHide }) => {

    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);



    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    description: message,
                    message: "Warning",

                    placement: "top"
                });
                return;
            }
            setData({ ...result });
        }
    };

    useEffect(() => {
        setVisible(true);
   
        getDataViaPrimaryKey();
    }, []);



    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSave = async (values) => {
        const _values  = { ...values, roles:values.roles?.map(item=>item["value"] ? item["value"] : item) };
        console.log(_values);
        const { success, message } =
            await put(`${Url}/${primaryKey}`, _values);
        if (!success) {
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };


    
    return (
        <Card style={ { width: "100vw", height: "100vh" } }> 
            <Row gutter={ 24 }>
                <Col span={ 8 }>
                    <Image
                        className="profile-picture"
                        src={ data?.image }
                        alt="Nft Image"
                        role="presentation"
                    />
                    <div style={ { marginBottom: 20 } }>
                        <Title level={ 2 }>{ data?.name }</Title>




                    </div>
                </Col>
                <Col span={ 16 }>

                    <List
                        bordered
                        dataSource={ [
                            { title: "ID", value: data?.id },
                            { title: "Slug", value: data?.slug },
                            { title: "Active", value: booleanIcon(data?.active) },
                            { title: "Amount", value: data?.amount },
                            { title: "Rank", value: data?.rank },
                            { title: "Type", value: data?.type },
                            { title: "Is Listed", value: booleanIcon(data?.is_listed) },
                            { title: "Collection Id", value: data?.collection_id },
                            { title: "Owner", value: data?.owner?.username },
                            { title: "Collection", value: data?.collection?.name }
                          
                        ] }
                        renderItem={ (item) => (
                            <List.Item>
                                <Text strong>{ item.title }:</Text> { item.value ?? "N/A" }
                            </List.Item>
                        ) }
                    />
                    { (
                        <>
                            <List
                                dataSource={ data?.attributes }
                                renderItem={ (item) => (
                                    <List.Item>
                                        <Text strong>Trait Type:</Text> { item.trait_type },{ " " }
                                        <Text strong>Value:</Text> { item.value },{ " " }
                                    </List.Item>
                                ) }
                                header={ (
                                    <div style={ { fontWeight: "bold" } }>
                                        Attributes:{ " " }
                                        <Tag color="blue">
                                            { data?.attributes.length }
                                        </Tag>
                                    </div>
                                ) }
                            />
                        </>
                    ) }
                </Col>

            </Row>

        </Card>



    );
};
export default Detail;
