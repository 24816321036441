export function removeStorage(key) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.log(
            "removeStorage: Error removing key [" +
            key +
            "] from localStorage: " +
            JSON.stringify(e)
        );
        return false;
    }
    return true;
}

export function getStorage(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        console.log(
            "getStorage: Error reading key [" +
            key +
            "] from localStorage: " +
            JSON.stringify(e)
        );
        return null;
    }
   
}

export function setStorage(key, value, expires) {
    if (expires === undefined || expires === null) {
        expires = 24 * 60 * 60; 
    }
    const now = Date.now();
    const schedule = now + expires * 1000;
    try {
        localStorage.setItem(key, value);
        localStorage.setItem(key + "_expiresIn", schedule);
    } catch (e) {
        console.log(
            "setStorage: Error setting key [" +
            key +
            "] in localStorage: " +
            JSON.stringify(e)
        );
        return false;
    }
    return true;
}
