import { CogSpinner } from "components";
import { AuthProvider, LayoutProvider } from "context";
import { Suspense } from "react";
import { Default } from "views";

const App = () => (
    <Suspense fallback={ <CogSpinner /> }>
        <LayoutProvider>
            <AuthProvider>
                <Default />
            </AuthProvider>
        </LayoutProvider>
    </Suspense>
);

export default App;
