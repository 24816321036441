/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import useSWR from "swr";
import { post } from "./HttpHelpers";

export const usePost = (ListUrl, queryParams) => {
    const fetcher = async () => await post(ListUrl, { ...queryParams });
    const swr = useSWR(ListUrl, fetcher);
    return {
        ...swr,
        isLoading: !swr.data && !swr.error
    };
};


export const usePersistedState = (key, initialState) => {
    const rawStateFromStorage = localStorage.getItem(key);
    const stateFromStorage =
        rawStateFromStorage && JSON.parse(rawStateFromStorage);

    const [value, setter] = useState(stateFromStorage || initialState);

    return [
        value,
        data => {
            localStorage.setItem(key, JSON.stringify(data));
            setter(data);
        }
    ];
};
