
import { Card, Col, Image, List, Row, Tag, Typography, notification } from "antd";
import { sourceOptions } from "constant/Table";

const { Title, Text } = Typography;
import { booleanIcon, formatResult, get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const Detail = ({ onHide }) => {

    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);



    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    description: message,
                    message: "Warning",

                    placement: "top"
                });
                return;
            }
            setData({ ...result });
        }
    };

    useEffect(() => {
        setVisible(true);
   
        getDataViaPrimaryKey();
    }, []);



    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSave = async (values) => {
        const _values  = { ...values, roles:values.roles?.map(item=>item["value"] ? item["value"] : item) };
        console.log(_values);
        const { success, message } =
            await put(`${Url}/${primaryKey}`, _values);
        if (!success) {
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };


    
    return (
        <Card style={ { width: "100vw", height: "100vh" } }> 
            <Row gutter={ 24 }>
                <Col span={ 8 }>
                    <Image
                        className="profile-picture"
                        src={ data?.image }
                        alt="User profile"
                        role="presentation"
                    />
                    <div style={ { marginBottom: 20 } }>
                        <Title level={ 2 }>{ data?.full_name ?? data?.username }</Title>
                        <List
                            grid={ { gutter: 16, column: 2 } }
                            dataSource={ data?.roles }
                            renderItem={ (role) => {
                                const color = role.name.length > 5 ? "geekblue" : "green";
                                return (
                                    <List.Item>
                                        <Tag color={ color } key={ role.id }>
                                            { role.name.toUpperCase() }
                                        </Tag>
                                    </List.Item>
                                );
                            } }
                            header={ (
                                <div style={ { fontWeight: "bold" } }>
                                    Roles:{ " " }
                                    <Tag color="blue">
                                        { data?.roles.length }
                                    </Tag>
                                </div>
                            ) }
                        />




                    </div>
                </Col>
                <Col span={ 16 }>

                    <List
                        bordered
                        dataSource={ [
                            { title: "ID", value: data?.id },
                            { title: "Source", value: data?.source },
                            { title: "Active", value: booleanIcon(data?.active) },
                            { title: "Mail is Allowed", value: booleanIcon(data?.mail_is_allowed) },
                            { title: "Username", value: data?.username },
                            { title: "Email", value: data?.email },
                            { title: "First Name", value: data?.first_name },
                            { title: "Last Name", value: data?.last_name },
                            { title: "Full Name", value: data?.full_name },
                            { title: "GSM", value: data?.gsm },
                            { title: "Notification ID", value: data?.notification_id },
                            { title: "Coin", value: data?.coin },
                            { title: "Gift Date", value: data?.gift_date },
                            { title: "Is Gift Get", value: booleanIcon(data?.is_gift_get) },
                            { title: "Is New", value: booleanIcon(data?.is_new) }
                        ] }
                        renderItem={ (item) => (
                            <List.Item>
                                <Text strong>{ item.title }:</Text> { item.value ?? "N/A" }
                            </List.Item>
                        ) }
                    />
                    {  (
                        <>
                            <List
                                dataSource={ data?.popups }
                                renderItem={ (item) => (
                                    <List.Item>
                                        <Text strong>Type:</Text> { item.type },{ " " }
                                        <Text strong>Amount:</Text> { item.amount },{ " " }
                                        <Text strong>Title:</Text> { item.title }
                                    </List.Item>
                                ) }
                                header={ (
                                    <div style={ { fontWeight: "bold" } }>
                                        Popups:{ " " }
                                        <Tag color="blue">
                                            { data?.popups.length }
                                        </Tag>
                                    </div>
                                ) }
                            />
                        </>
                    ) }
                </Col>

            </Row>

        </Card>



    );
};
export default Detail;
