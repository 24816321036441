import { Image } from "antd";
import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        title: "Image",
        dataIndex: "image",
        render: image => (
            <Image width={ 60 }
                height={ 60 }
                style={ { objectFit: "cover" } }
                src={ image } />
        )
    },
    {
        title: "Description",
        dataIndex: "description"
    },
    {
        title: "Slug",
        dataIndex: "slug"
    },
    {
        title: "Amount",
        dataIndex: "amount"
    },
    {
        title: "Type",
        dataIndex: "type"
    }
];
const Path = "content/content/consumable-list";
const Url = "/consumable/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide } /> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide } /> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

