import { Button, Form, Input, Modal, notification } from "antd";
import { get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const AddModal = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    message: "Warning",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Modal visible={ visible } 
            title= { primaryKey ? "Edit Role" : "Add Role" }
            onCancel={ onClose }
            okButtonProps = { { style: { display: "none" } } }
            cancelButtonProps={ { style: { display: "none" } } }>
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Role Name"
                    name="name"
                    rules={ [{ required: true, message: "Please type role name!" }] }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={ [{ required: true, message: "Please type role description!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddModal;
