import { Card } from "antd";
import { DataTable } from "components";
import { initialFilter } from "constant/Table";
import { useEffect } from "react";

const TableContent = ({ tableData, tableColumns, dataFilter, title, queryParams, setQueryParams }) => {

    useEffect(() => {
        return () => setQueryParams(initialFilter);
    }, []);

    return (
        <Card title={ title } bordered={ false }>
            { dataFilter }
            <DataTable 
                useData={ tableData } 
                columns={ tableColumns } 
                queryParams={ queryParams }
                setQueryParams={ setQueryParams }
            />
        </Card>
    );
};

export default TableContent;
