export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
];
export const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: { active : 1 }
};
export const statusOptions = [
    { value: "", label: "Tümü" },
    { value: "1", label: "Aktif" },
    { value: "0", label: "Pasif" }
];


export const sourceOptions = [
    { value: "web", label: "Web" },
    { value: "mobile", label: "Mobile" },
    { value: "cms", label: "Cms" },
    { value: "cli", label: "Cli" }
];

export const itemTypeOptions = [
    { value: "coin", label: "Coin" }
];

export const consumableTypeOptions = [
    { value:"gift_card", label: "Gift Card" },
    { value:"ticket", label: "Ticket" },
    { value:"game", label: "Game" },
    { value:"food", label: "Food" },
    { value: "transport", label: "Transport" },
    { value: "clothing", label: "Clothing" },
    { value: "app_credit", label: "App Credit" },
    { value: "refill", label: "Refill" }
];

export const countryOptions = [
    { value:"Us", label: "United States" },
    { value:"Global", label: "Global" },
    { value:"Turkey", label: "Turkey" },
    { value:"Eu", label: "Europa" },
    { value: "Arabia", label: "Arabia" }
];

export const storeOptions = [
    { value: "ios", label: "Ios" },
    { value: "android", label: "Android" }
];

export const transactionTypeOptions = [
    { value: "incoming", label: "Incoming" },
    { value: "outcoming", label: "Outcoming" }
];
export const typeOptions = [
    { value: "game_transaction_history", label: "Game Transaction History" },
    { value: "gift_transaction_history", label: "Gift Transaction History" },
    { value: "nft_transaction_history", label: "Nft Transaction History" }
];
export const currencyTypeOptions = [
    { value: "xp", label: "Xp" },
    { value: "coin", label: "Coin" }

];
export const booleanOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }


];
export const sizes = [
    { label: "3", value: 3 },
    { label: "5", value: 5 },
    { label: "10", value: 10 }
];
export const infoFormItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 14
    }
};
  

