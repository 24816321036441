import { Tag } from "antd";

export const handleTableChange = (setQueryParams) => {
    return(pagination, filters, sorter) => {
        const pageNumber = pagination.current || 1;
        const pageSize = pagination.pageSize || 10;
        const sortField = sorter?.field || "id";
        const sortOrder = sorter?.order === "ascend" ? "asc" : "desc" || "desc";
        setQueryParams((prev) => ({ ...prev, sortField, sortOrder, pageNumber, pageSize }));
    };
    
};
export const formatResult = (datas) => {
    return datas?.map(item => ({ label: item.name, value: item.id }));
};

export const formatValue = (data) => ({ label: data.name, value: data.id });


export const getColumns = (
    cols = [],
    renderAction = null,
    includeOperation = true
) => {
    const columns = [
        {
            dataIndex: "id",
            title: "ID",
            width: 40,
            sorter: true,
            defaultSortOrder: "descend"
        }
    ];
    const actionColumns = [
        {
            dataIndex: "active",
            title: "Durum",
            width: 100,
            render: (active) => ( <Tag color={ active ? "green" : "volcano" }>
                { active ? "Aktif": "Pasif" }
            </Tag>
            )
        },
        includeOperation?{
            title: "İşlem",
            dataIndex: "",
            width: 120,
            render: renderAction
        }:{}
    ];
    return [...columns, ...cols, ...actionColumns];
};



