import { Button, Form, Input, Modal, Select, notification } from "antd";
import { currencyTypeOptions } from "constant/Table";
import { formatResult, formatValue, get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const AddModal = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [types, setTypes] = useState(undefined);
    const [games, setGames] = useState(undefined);

    const getTypes = async () => {
        const { result, success, message } = await post("/game_item_type/back-office/list",
            { filter: { active: 1 }, pageSize: 1000 });
        if (!success) {
            notification.error({
                description: message,
                message: "Warning",

                placement: "top"
            });
            return;
        }
        const types_ = formatResult(result);
        setTypes(types_);

    };

    const getGames = async () => {
        const { result, success, message } = await post("/game/back-office/list",
            { filter: { active: 1 }, pageSize: 1000 });
        if (!success) {
            notification.error({
                description: message,
                message: "Warning",

                placement: "top"
            });
            return;
        }
        const games_ = formatResult(result);
        setGames(games_);

    };

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    message: "Warning",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setData({ ...result,  game: formatValue(result?.game), type: 
                formatValue(result?.type) });
        }
    };

    useEffect(() => {
        setVisible(true);
        getTypes();
        getGames();
        getDataViaPrimaryKey();


    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        
            
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const _values  = { ...values, game:values?.game["value"]??values.game, type:values?.type["value"]??values.type };
        console.log(_values);
        const { success, message } = !primaryKey ? await post(`${Url}/add`, _values) : 
            await put(`${Url}/${primaryKey}`, _values);
        if(!success){
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };



    return (
        <Modal visible={ visible } 
            title= { primaryKey ? "Edit Game Item" : "Add Game Item" }
            onCancel={ onClose }
            okButtonProps = { { style: { display: "none" } } }
            cancelButtonProps={ { style: { display: "none" } } }>
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={ [{ required: true, message: "Please type game name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={ [{ required: true, message: "Please type game name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slug"
                    name="slug"
                    rules={ [{ required: true, message: "Please type game name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Value"
                    name="value"
                    rules={ [{ required: true, message: "Please type game name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Amount"
                    name="amount"
                    rules={ [{ required: true, message: "Please type game name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Currency Type" name="currency_type"
                    rules={ [{ required: true, message: "Please select currency type!" }] }
                    style={ { width: "100%" } }>
                    <Select options={ currencyTypeOptions } />
                </Form.Item>
                <Form.Item label="Game" name="game"
                    rules={ [{ required: true, message: "Please select game!" }] }
                    style={ { width: "100%" } }>
                    <Select options={ games } />
                </Form.Item>
                <Form.Item label="Type" name="type"
                    rules={ [{ required: true, message: "Please select type!" }] }
                    style={ { width: "100%" } }>
                    <Select options={ types } />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddModal;
