import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        dataIndex:"order_key",
        title:"Order Key"
    },

    {
        dataIndex:"amount",
        title:"Amount"
    },
    {
        dataIndex: "user",
        title: "User",
        render: user => user?.username
    }
];

const Path = "/content/transactions/order-list";
const Url = "/order/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide }/> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

