import { Button, Form, Input, Row, Select, notification } from "antd";
import { sourceOptions, statusOptions } from "constant/Table";
import { formatResult, post } from "helper";
import { useEffect, useState } from "react";

const FilterMenu = ({ handleSubmit }) => {

    const [roles, setRoles] = useState(undefined);

    const filterOptions = [
        { value: "email", label: "Email" },
        { value: "gsm", label: "Gsm" },
        { value: "username", label: "Username" },
        { value: "first_name", label: "First Name" },
        { value: "last_name", label: "Last Name" }
    ];
      

    const getRoles = async () => {
        const { result, success, message } = await post("/role/back-office/list",
            { filter: { active: 1 }, pageSize: 1000  });
        if (!success) {
            notification.error({
                description: message,
                message: "Warning",
                placement: "top"
            });
            return;
        }
        const roles_ = formatResult(result);
        setRoles(roles_);
    };

    useEffect(() => {
        getRoles();
    }, []);

    return (
        <Form
            layout="vertical"
            requiredMark={ "Required" }
            initialValues={ { active: statusOptions[1].value } }
            onFinish={ handleSubmit }
        >
            <div className="filterBox">
                <Row>
                    <div className="filterTitle">Filter Options</div>
                </Row>
                <Row>
      
                    <Form.Item label="Filter" name="filter" tooltip="Filter Input" style={ { width: "100%" } }>
                        <Input.Group compact>
                            <Form.Item name="filter_input" style={ { width: "50%" } } >
                                <Input />
                            </Form.Item>
                            <Form.Item name="filter_option" style={ { width: "50%" } } >
                                <Select options={ filterOptions } />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                  
                    <Form.Item label="Source" name="source"
                        tooltip="Record Source" style={ { width: "100%" } }>
                        <Select options={ sourceOptions } />
                    </Form.Item>
                    <Form.Item label="Rol" name="roles"
                        style={ { width: "100%" } }>
                        <Select
                            placeholder="Rol seçiniz"
                            mode="multiple" options={ roles } />
                    </Form.Item>
                    <Form.Item label="Durum" name="active"
                        required tooltip="Veri durumu" style={ { width: "100%" } }>
                        <Select options={ statusOptions } />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Button style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">Filter</Button>
                </Row>
            </div>
        </Form>
    );
};
export default FilterMenu;
