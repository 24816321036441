import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Upload, notification } from "antd";
import { consumableTypeOptions, countryOptions } from "constant/Table";
import { get, post, put } from "helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Url } from "../Constant";

const AddModal = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [fileBase64, setFileBase64] = useState("");

    const handleFileChange = (info) => {
        if (info.file) {

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setFileBase64(base64String);
            };
            reader.readAsDataURL(info.file);
        }
    };
      
    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    message: "Warning",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);
    
    useEffect(() => {
        if(visible){
           
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const _values  = { ...values, image:fileBase64 };
        const { success, message } = !primaryKey ? await post(`${Url}/add`, _values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };


    return (
        <Modal visible={ visible } 
            title= { primaryKey ? "Edit Consumable" : "Add Consumable" }
            onCancel={ onClose }
            okButtonProps = { { style: { display: "none" } } }
            cancelButtonProps={ { style: { display: "none" } } }>
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Description"
                    name="description"
                    rules={ [{ required: true, message: "Please type description name!" }] }
                >
                    <Input />
                </Form.Item>               
                <Form.Item
                    label="Slug"
                    name="slug"
                    rules={ [{ required: true, message: "Please type slug name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Amount"
                    name="amount"
                    rules={ [{ required: true, message: "Please type amount name!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Image" name="image" rules={ [{ required: true, message: "Please upload an image!" }] }>
                    <Upload
                        beforeUpload={ () => false }
                        onChange={ handleFileChange }
                       
                        maxCount={ 1 }
                    >
                        <Button icon={ <UploadOutlined /> } size="small">
                            Upload Image
                        </Button>
                    </Upload>

                </Form.Item>
                <Form.Item label="Type" name="type"
                    required tooltip="Record Type" style={ { width: "100%" } }>
                    <Select options={ consumableTypeOptions } />
                </Form.Item>
                <Form.Item label="Country" name="country"
                    required tooltip="Record Type" style={ { width: "100%" } }>
                    <Select options={ countryOptions } />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={ { width: "100%" } } shape="round" type="outline" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddModal;
