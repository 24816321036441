import { TableContent } from "components";
import { initialFilter } from "constant/Table";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Filter from "./Filter";
import { Router } from "../Constant";
import { useData, useTable  } from "../Helper";

const Page = () => {
    const [queryParams, setQueryParams] = useState(initialFilter);
    const { mutate } = useData(queryParams);
    const { columns, onHide } = useTable(mutate);

    return (
        <>
            <TableContent
                title="Game Item Type Management"
                tableData={ useData } 
                tableColumns={ columns } 
                dataFilter={ <Filter queryParams={ queryParams } setQueryParams={ setQueryParams }/> } 
                queryParams={ queryParams }
                setQueryParams={ setQueryParams }
            />
            <Router onHide={ onHide } />
            <Outlet />
        </>
       
    );
};
export default Page;
