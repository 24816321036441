import { Image } from "antd";
import { booleanIcon } from "helper";
import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        title: "Logo",
        dataIndex: "logo",
        render: logo => (
            <Image width={ 60 }
                height={ 60 }
                style={ { objectFit: "cover" } }
                src={ logo } />
        )
    },
    {
        title: "Background Image",
        dataIndex: "background_img",
        render: background_img => (
            <Image width={ 60 }
                height={ 60 }
                style={ { objectFit: "cover" } }
                src={ background_img } />
        )
    },
    {
        title: "Name",
        dataIndex: "name"
    },
    {
        title: "Title",
        dataIndex: "title"
    },
    {
        title: "Sub Title",
        dataIndex: "sub_title"
    },
    {
        title: "Description",
        dataIndex: "description"
    },
    {
        title: "Slug",
        dataIndex: "slug"
    },
    {
        title: "Launched",
        dataIndex: "launched",
        render: (launched) => booleanIcon(launched)
    },
    {
        title: "Collectibles",
        dataIndex: "collectibles"
    },
    {
        title: "Ungenerated",
        dataIndex: "un_generated"
    },
    {
        title: "Listed",
        dataIndex: "listed"
    },
    {
        title: "Floor Price",
        dataIndex: "floor_price"
    }
];
const Path = "content/content/collection-list";
const Url = "/collection/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide } /> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide } /> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

