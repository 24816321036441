import { Error, Gate } from "components";
import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoutes, SignInPage } from "views";
const AuthRoute = () => (
    <Gate>
        <SignInPage />
    </Gate>
);

const ContentRoute = () => (
    <Gate guard={ true }>
        <PrivateRoutes />
    </Gate>
);

const Default = () => (
    <Routes>
        <Route path="/" element={ <Navigate to="/content/dashboard" /> } />
        <Route path="auth/login" element={ <AuthRoute /> } />
        <Route path="content/*" element={ <ContentRoute /> } /> 
        <Route path="*" element={ <Error /> } />
    </Routes>
);

export default Default;
