import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, Row, Typography, notification } from "antd";
import { CogSpinner, Footer } from "components";
import { useAuth } from "context";
import { post } from "helper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Page = () => {
    const navigate = useNavigate();
    const { createSession } = useAuth();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (data) => {
        const _data  = { ...data, active: true, id: 0 };
        setLoading(true);
        setTimeout( async () =>{ 
            try {
                const { user, tokens, success, message } = await post("/authenticate/back-office/login", _data);
                if(!success){
                    notification.error({
                        message: "Warning",
                        description: message,
                        placement: "top"
                    });
                    return;
                }
                await createSession({ tokens, user });
                navigate("/content/dashboard");
            } catch (error) {
                notification.error({
                    message: "Warning",
                    description: error.toString(),
                    placement: "top"
                });
            }
            finally{
                setLoading(false);
            }
        }, 1000);
    };

    return (
        <>
            <Row type="flex" align="middle" justify="center" style={ { height: "100vh", backgroundImage:
        "url(" + require("assets/img/register_bg_2.png") + ")", backgroundColor: "rgba(30, 41, 59, 1)" } }>
                <Col>
                    <Typography.Title align="center" 
                        style={ { color: "#FFF", marginBottom: 10 } } level={ 1 }>
                        RATIC GAMES
                    </Typography.Title>
                    <Card bordered={ false } style={ { width: 400 } }>
                        <Typography.Title align="center" level={ 5 } style={ { fontWeight: "normal" } }>
                            You can login with your credentials!
                        </Typography.Title>
                        <Divider />
                        <Form
                            layout="vertical"
                            requiredMark={ "Required" }
                            onFinish={ handleSubmit }
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={ [{ required: true, message: "Please type your email!" }] }
                            >
                                <Input size="large" 
                                    prefix={ <UserOutlined style={ { color: "rgba(30, 41, 59, 1)" } }/> }/>
                            </Form.Item>

                            <Form.Item
                                label="Parola"
                                name="password"
                                rules={ [{ required: true, message: "Please type your password!" }] }
                            >
                                <Input.Password size="large" 
                                    prefix={ <LockOutlined style={ { color: "rgba(30, 41, 59, 1)" } } /> } />
                            </Form.Item>

                            <Form.Item>
                                <Button size="large" 
                                    style={ { width: "100%" } } type="primary" htmlType="submit">
                                    LOGIN
                                </Button>
                            </Form.Item>
                        </Form>
                        { loading && <CogSpinner /> }
                    </Card>
                </Col>
            </Row>
            <Footer style={ { position:"absolute", width: "100%", 
                background:"transparent", color:"white", textAlign:"center", bottom: 0 } }/>  

        </>
    );
};

export default Page;
