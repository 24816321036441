import { Route, Routes } from "react-router-dom";
import { AddModal } from "./add";

const Columns = [
    {
        dataIndex: "name",
        title: "Role"
    },
    {
        dataIndex: "description",
        title: "Description"
    }
];

const Path = "/content/user/role-list";
const Url = "/role/back-office";
const ListUrl = `${Url}/list`;
const ExcelUrl = `${Url}/list_as_excel`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddModal onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddModal onHide={ onHide }/> } />
    </Routes>
);

export { Columns, Path, Url, ListUrl, ExcelUrl, Router };

