import { Button, Popconfirm, notification } from "antd";
import { delete_, getColumns } from "helper";
import { usePost } from "helper/Hook";
import { useNavigate } from "react-router-dom";
import { Columns, ListUrl, Path, Url } from "./Constant";

export const useTable = (mutateData) => {
    const navigate = useNavigate();

    const onHide = async () => {
        await mutateData();
        navigate(Path);
    };

    const deleteAction = async (primaryKey) => {
        const { success, message } = await delete_(`${Url}/${primaryKey}`);
        if(!success){
            notification.error({
                message: "Warning",
                description: message,
                placement: "top"
            });
            return;
        }
        onHide();
    };

    const renderAction = (_, record) => (
        <>
            <Button onClick={ () => navigate(`/content/content/collection/${record.id}`) } type="link" size="small">
                <i className="flaticon-eye text-muted" />
            </Button>
            <Button onClick={ () => navigate(`${record.id}/edit`) } type="link" size="small">
                <i className="flaticon2-edit text-muted" />
            </Button>
            <Popconfirm okText="Yes" cancelText="No" 
                title="Are you sure?" onConfirm={ () => deleteAction(record.id) }>
                <Button type="link" size="small" danger>
                    <i className="flaticon2-trash" />
                </Button>
            </Popconfirm>
        </>
        
    );
    
    const columns = getColumns(Columns, renderAction);
    return { columns, onHide };
};


export const useData = (queryParams) => {
    return usePost(ListUrl, queryParams);
};
