/* eslint-disable no-restricted-imports */
import { CloudDownloadOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Input, Row, Space } from "antd";
import { post } from "helper";
import { isEqual } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterMenu from "./FilterMenu";
import { ExcelUrl } from "../Constant";

const prepareFilter = (queryParams, values) => {
    const { active, name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (active) filter.active = active;
    if (name) filter.name = name;
    newQueryParams.filter = filter;
    return newQueryParams;
};

const Filter = ({ queryParams, setQueryParams }) => {
    const push = useNavigate();
    const [loading, setLoading] = useState(false);

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const excelQueryParams = { ...queryParams, pageNumber: 0, pageSize: 0 };

    const downloadData = async () => {
        setLoading(true);
        const response = await post(ExcelUrl, excelQueryParams, { responseType: "blob" });
        if (response) {
            let url = window.URL.createObjectURL(response);
            let a = document.createElement("a");
            a.href = url;
            a.download = "exportExcel.xlsx";
            a.click();
            setLoading(false);
        }

    };

    const handleAdd = () => {
        push("add");
    };
    const handleSubmit = (values) => applyFilter({ ...queryParams.filter, ...values });

    const _handleInputChange = (event) => applyFilter({ ...queryParams.filter, name: event.target.value });

    const filterMenu = () => <FilterMenu handleSubmit={ handleSubmit } />;

    return (
        <Form
            layout="vertical"
            requiredMark={ "Required" }
        >
            <Row gutter={ 24 }>
                <Col span={ 6 }>
                    <div style={ { "marginBottom": "10px" } }>
                        <Form.Item required>
                            <Input onChange={ _handleInputChange } placeholder="Search" />
                        </Form.Item>
                    </div>
                </Col>
                <Col style={ { marginLeft: "auto" } }>
                    <Space>
                        <Button shape="round" type="primary"
                            onClick={ downloadData } block icon={ <CloudDownloadOutlined /> } loading={ loading }>
                            Download as Excel
                        </Button>
                        <Button shape="round" type="dashed" onClick={ handleAdd } block icon={ <PlusOutlined /> }>
                            New
                        </Button>
                        <Dropdown overlay={ filterMenu } trigger={ ["click"] }>
                            <Button shape="round" type="primary">
                                Filter
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
export default Filter;
